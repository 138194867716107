import React from "react"
import SEO from 'components/SEO/seo'
import HighSchool from "components/Textbooks/HS";
import MainTemplate from 'templates/MainTemplate'


const SPPs = () => (
  <MainTemplate>
     <SEO title="Podręczniki dla szkół ponadpodstawowych" /> 
  <HighSchool/>
  
  </MainTemplate>
)

export default SPPs
