import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";

const StyledDiv=styled.div`
width: 100%;
min-width:200px;
max-width:400px;
margin-top:15px;
padding-top: 5px;
display: flex;
flex-direction:column;
border-top: 1px solid rgba(0, 0, 0, 0.15);
`

const Button=styled.button`
width:55%;
min-width:210px;
background-color: ${({pakiet}) => pakiet ? ({theme}) => theme.green : ({theme}) => theme.fontlightblue};
border: none;
border-radius: 5px;
margin-top:5px;
padding: 8px 10px;
transition: 0.3s ease-in;

${({theme}) => theme.media.desktop} {
  width:75%;
min-width:207px;
background-color: ${({pakiet}) => pakiet ? ({theme}) => theme.green : ({theme}) => theme.fontlightblue};
border: none;
border-radius: 5px;
padding: 7px 10px;

transition: 0.3s ease-in;

&:hover{
  background-color: ${({theme}) => theme.blue};
  text-decoration: none;
}
}
`
const StyledA=styled.a`
color:${({theme}) => theme.white};
font-weight: 400;
font-size: ${({theme}) => theme.font.size.xxs};
transition: 0.3s ease-in;
&:hover{
  color:${({theme}) => theme.white};
  text-decoration: none
}

`
const StyledP = styled.div`
font-size: ${({theme}) => theme.font.size.s};
font-weight: 600;
color: ${({theme}) => theme.blackfont};
padding: 1px;

&:first-child{
  padding-top: 2px;
}

&:last-child{
  padding-bottom: 2px;
}

`

const Pakiet = () => {
    const data = useStaticQuery(graphql`
  query{
    allDatoCmsPakiet {
    nodes {
      id
      pakietOffOn
      link
      name
      forWhom
    }
  }
  }

 `)


return (
<>
{data.allDatoCmsPakiet.nodes.map(Pakietx => (
  <div key={Pakietx.id}>
    {Pakietx.pakietOffOn&&<StyledDiv>
    <StyledP>{Pakietx.forWhom}</StyledP>
      <Button pakiet>
       <StyledA pakiet href={Pakietx.link}>{Pakietx.name}</StyledA>
    </Button>
    </StyledDiv>}
    </div>
))}
</>
);
}
export default Pakiet;